<template>
  <div class="greg_cal">
      <div class="gred_cal_view">
            <div class="info_box">
              <div class="overall-adherence">{{ $t('average_digital_manual_adherence') }}: {{
                (((getAdherenceData.technologyDoses + getAdherenceData.manualDoses) / getAdherenceData.totalDoses) * 100).toFixed(2)
              }}%</div>
              <div class="digital-plus-manual-adherence">{{ $t('digital_plus_manual_adherence') }}: {{
                getAdherenceData.technologyDoses + getAdherenceData.manualDoses
              }}</div>
              <div class="last-dosage">{{ $t('last_dosage') }}: {{
                getAdherenceData.lastDosage != null ? getAdherenceData.lastDosage.split(' ')[0] : '-'
              }}</div>
            </div>
            <CalendarLegend
              class="legend-container"
              v-if="showLegend"
              @legend-mouseenter = "performActionLegendMouseenter"
              @legend-mouseleave = "performActionLegendMouseleave"
              @legend-mouseclick = "performLegenedMouseClick"/>
            <br />
            <div class="action_buttons">
                <div
                    @click="legendBtnClickAction"
                    v-if="positiveAction"
                    class="action-positive-btns">
                    <div class="show-legend-btn">
                      <Button
                        :label="legendBtnText"
                        type="none"
                      />
                      <font-awesome-icon :icon="toggleBtnIcon" />
                    </div>
                    <select-on-hover-component
                        class="alter-tags"
                        @select-on-hover-click = "addRemoveTags"
                        :label="$t('add_remove_tags')"
                        :items="alterTagsDropDownValues" />
                    <!-- code commented for later use (new marking dose flow)
                      <select-on-hover-component
                        :label="$t('alter_dose')"
                        @select-on-hover-click = "alterDoseOnHoverSelectOnClick"
                        :items="alterDoseDropDownValues" /> -->
                    <Button
                        @click="alterDoseOnHoverSelectOnClickTest('MISSED')"
                        :label="$t('mark_remove_missed_doses')" />
                    <Button
                        @click="alterDoseOnHoverSelectOnClickTest('MANUAL')"
                        :label="$t('mark_remove_manual_doses')" />
                </div>
                <div
                    v-if="!positiveAction"
                    class="manual-marking-confirm-canel">
                    <p class="dose-info-text">
                        <font-awesome-icon icon="info-circle" />
                        {{ $t(`select_dates_below_or_click_here_to_cancel`) }}
                    </p>
                    <Button
                        @click="positiveActionCancelButton"
                        :label="$t('cancel_marking')"
                        type="outlined">
                    </Button>
                    <Button
                        v-if="!unknwonDateSelection"
                        :disabled="codesSelected.length === 0 || !confirmBtnEnabledForTags"
                        @click="confirmBtnAction"
                        :label="$t(confirmBtnText)">
                    </Button>
                    <select-on-hover-component
                        v-if="unknwonDateSelection"
                        @select-on-hover-click = "selectOnHoverAction"
                        :label="'Choose Action'"
                        :items="onHoverComponentList" />
                </div>
            </div>
            <table class="greg_table">
                <thead>
                    <tr>
                        <th class="patient-month-header">{{ $t('Month') }}</th>
                        <th class="patient-year-header">{{ $t('year') }}</th>
                        <th v-for="i in maxDaysInGivenMonths" :key="i">{{i}}</th>
                    </tr>
                </thead>
                <tbody>
                  <tr v-for="(month, i) in getAdherenceData.adherenceMonthWiseData" :key="i">
                    <td class="month_name">{{$t('_' + month.monthName.toLowerCase())}}</td>
                    <td class="month_year">{{month.year}}</td>
                    <td class="greg_day_container" v-for="(day, j) in month.adherenceDataForCalendar" :key="month + '_' + j">
                        <calendar-day
                            :class="[{
                                day_selected: codesSelected.map(m => m.dayId).includes((j + 1) + '_' + month.monthNumber + '_' + month.year),
                                highlight: (!highlightCodes.includes(day.code) && highlightCodes.length > 0) || (datesToHighlight.length > 0 && !datesToHighlight.includes(j + '_' + month.monthNumber + '_' + month.year))
                            }]"
                            :id="`${day.dayName.toLowerCase()} ${j+1} ${month.monthName.toLowerCase()}, ${month.year}`"
                            v-if="day.code !== '7'"
                            style="cursor: pointer"
                            :dayData="day"
                            :monthData="month"
                            :day="j + 1"
                            :firstDay="firstDayDate === (j + '_' + month.monthNumber + '_' + month.year)"
                            :endDay="patientData != null && patientData.episodeData != null && patientData.episodeData.stageData.treatmentOutcome !== null && endDateFormat === (j + '_' + month.monthNumber + '_' + month.year)"
                            @day-click="performDayClickAction"
                            @day-mouseover-ctrl="performDayMouseoverCtrlAction"
                            @day-mouseover="performDayMouseoverAction"
                            @day-mouseleave="performDayMouseLeaveAction" />
                    </td>
                    <td
                      v-for="idx in maxDaysInGivenMonths - month.adherenceDataForCalendar.length" :key="month.daysInMonth - idx"></td>
                  </tr>
                </tbody>
            </table>
      </div>
    <speech-bubble
        ref = 'speech_bubble'
        v-if="showSpeechBubble"
        class="speech-bubble-greg-cal"
        :style="{left: leftCoordinates + 'px', top: topCoordinates + 'px' }">
            <calenday-day-speech-bubble-content
                :heading="speechBubbleHeading"
                :doseType="speechBubbleDoseType"
                :items = speechBubbleItems />
    </speech-bubble>
    <Modal
      @close = "modalCloseAction"
      @click = 'modalClickConfirm'
      :disableConfirmBtn="disableModalConfirmBtn"
      :confirmText="confirmBtnText"
      v-if="showModal"
      :headingText="$t('alter_dose')">
      <div slot="body">
        <div class="modal-sub-heading-text">
          <span class="modal-sub-text-label">{{ $t('alter_doses_for_date') }}</span>
          <p style="max-height:200px;overflow-y:auto">{{codesSelected.map(m => m.dayId.replace(/_/g, "-")).toString().replace(/,/g, ", ")}}</p>
        </div>
          <Select
            class="tagsSelect"
            name="tagsSelect"
            v-model="selectedTags"
            :label="$t('select_patient_tags')"
            :allOptions="tagsForSelector"
            orientation="v"
            v-if="currentDoseMarkingState === 'ADD_TAGS' || currentDoseMarkingState === 'REMOVE_TAGS'"
            :is-multi-select="true" />
        <TextArea
          @input="textAreaInputChange"
          label="Additional Notes"
          orientation="v"
          class="modal-additional-notes" />
      </div>
    </Modal>
  </div>
</template>

<script>
import CalendarDay from './CalendarDay.vue'
import CalendarLegend from './CalendarLegend.vue'
import SpeechBubble from './SpeechBubble.vue'
import { calendarDaySpeechBubbleMixin } from './mixin/calendarDaySpeechBubbleMixin'
import SelectOnHoverComponent from './SelectOnHoverComponent.vue'
import CalendayDaySpeechBubbleContent from './CalendayDaySpeechBubbleContent.vue'
import { mapActions, mapState } from 'vuex'
import Button from '../../../../../shared/components/Button.vue'
import Modal from '../../../../../shared/components/Modal.vue'
import TextArea from '../../../../../shared/components/TextArea.vue'
import Select from '../../../../../shared/components/Select.vue'
import { defaultToast, ToastType } from '../../../../../../utils/toastUtils'
export default {
  name: 'GregorianCalendar',
  async mounted () {
    await this.getTagsConfig()
    await this.getAdherenceDataAsync()
  },
  components: {
    CalendarDay,
    CalendarLegend,
    SpeechBubble,
    SelectOnHoverComponent,
    CalendayDaySpeechBubbleContent,
    Button,
    Modal,
    TextArea,
    Select
  },
  data () {
    return {
      codesSelected: [],
      legendClicked: false,
      positiveAction: true,
      confirmBtnText: '',
      unknwonDateSelection: false,
      doseMarkingByBtn: false,
      onHoverComponentList: {},
      highlightCodes: [],
      tagsSelection: false,
      alterDoseDropDownValues: {
        MARK_MANUAL_DOSE: 'mark_manual_dose',
        REMOVE_MANUAL_DOSE: 'remove_manual_dose',
        MARK_MISSED_DOSE: 'mark_missed_dose',
        REMOVE_MISSED_DOSE: 'remove_missed_dose'
      },
      alterTagsDropDownValues: {
        ADD_TAGS: 'add_tags',
        REMOVE_TAGS: 'remove_tags'
      },
      alterDoseActionToCodeValue: {
        MARK_MANUAL_DOSE: '6',
        MARK_MISSED_DOSE: '6',
        REMOVE_MANUAL_DOSE: '9',
        REMOVE_MISSED_DOSE: '2'
      },
      alterDoseCodeMap: {
        MANUAL: ['6', '9'],
        MISSED: ['6', '2']
      },
      currentDoseMarkingState: '',
      alterDoseUncertainAction: '',
      showModal: false,
      modalNotesValue: '',
      disableModalConfirmBtn: false,
      selectedTags: [],
      datesToHighlight: [],
      showLegend: false,
      legendBtnText: 'show_legend',
      toggleBtnIcon: 'caret-down',
      confirmBtnEnabledForTags: true
    }
  },
  watch: {
    selectedTags: {
      handler: function (val, oldVal) {
        this.disableModalConfirmBtn = !(val.length > 0)
      },
      immediate: true
    },
    currentDoseMarkingState (val) {
      if (['ADD_TAGS', 'REMOVE_TAGS'].includes(this.currentDoseMarkingState)) {
        this.disableModalConfirmBtn = true
      } else {
        this.disableModalConfirmBtn = false
      }
    }
  },
  methods: {
    ...mapActions('Adherence', [
      'getPatientMonthwiseAdherence',
      'markDoses',
      'addTags',
      'removeTags',
      'getTagsConfig',
      'getEpisodeTags'
    ]),
    async getAdherenceDataAsync () {
      await this.getPatientMonthwiseAdherence(this.$route.params.patientId)
    },
    textAreaInputChange (value) {
      this.modalNotesValue = value
    },
    confirmBtnAction () {
      if ((this.confirmBtnText === this.alterTagsDropDownValues.ADD_TAGS || this.confirmBtnText === this.alterTagsDropDownValues.REMOVE_TAGS) && this.codesSelected.length === 1) {
        this.loadTags()
      }
      this.showModal = true
    },
    async loadTags () {
      var selectedDatesWithIds = this.codesSelected.map(m => {
        var parts = m.dayId.split('_')
        var date = new Date(parts[2], parts[1] - 1, parts[0])
        var userTimezoneOffset = date.getTimezoneOffset() * 60000
        return new Date(date.getTime() - userTimezoneOffset).toISOString()
      })
      const response = await this.getEpisodeTags({
        episodeId: this.$route.params.patientId,
        dateTime: selectedDatesWithIds.at(0)
      })
      this.selectedTags = this.currentTagsWithTranslations(response)
    },
    modalCloseAction () {
      this.showModal = false
      this.modalNotesValue = false
    },
    alterDoseOnHoverSelectOnClickTest (value) {
      this.positiveAction = false
      this.doseMarkingByBtn = true
      this.alterDoseUncertainAction = value
      this.confirmBtnText = 'select_dates'
      this.highlightCodes = this.alterDoseCodeMap[value]
    },
    alterDoseOnHoverSelectOnClick (index, value) {
      this.positiveAction = false
      this.doseMarkingByBtn = true
      this.currentDoseMarkingState = value
      this.confirmBtnText = this.alterDoseDropDownValues[value]
      this.highlightCodes = [this.alterDoseActionToCodeValue[value]]
    },
    async modalClickConfirm () {
      this.confirmBtnText = 'Loading'
      this.disableModalConfirmBtn = true
      if (this.currentDoseMarkingState === 'MARK_MANUAL_DOSE' || this.currentDoseMarkingState === 'REMOVE_MANUAL_DOSE') {
        const editDosePayLoad = this.getEditDosePayload()
        editDosePayLoad.payload.addDoses = this.currentDoseMarkingState === 'MARK_MANUAL_DOSE'
        editDosePayLoad.payload.code = 'MANUAL'
        await this.markDoses(editDosePayLoad)
      } else if (this.currentDoseMarkingState === 'MARK_MISSED_DOSE' || this.currentDoseMarkingState === 'REMOVE_MISSED_DOSE') {
        const editDosePayLoad = this.getEditDosePayload()
        editDosePayLoad.payload.addDoses = this.currentDoseMarkingState === 'MARK_MISSED_DOSE'
        editDosePayLoad.payload.code = 'MISSED'
        await this.markDoses(editDosePayLoad)
      } else if (this.currentDoseMarkingState === 'ADD_TAGS') {
        await this.addTags(this.getEditTagsPayload(this.currentDoseMarkingState))
      } else if (this.currentDoseMarkingState === 'REMOVE_TAGS') {
        await this.removeTags(this.getEditTagsPayload(this.currentDoseMarkingState))
      }
      this.positiveActionCancelButton()
      this.showModal = false
    },
    getEditTagsPayload (tagActionType) {
      var selectedDatesWithIds = this.codesSelected.map(m => {
        var parts = m.dayId.split('_')
        var date = new Date(parts[2], parts[1] - 1, parts[0])
        var userTimezoneOffset = date.getTimezoneOffset() * 60000
        return ({ Date: new Date(date.getTime() - userTimezoneOffset).toISOString() })
      })
      const tagsDataList = []
      if (selectedDatesWithIds && selectedDatesWithIds.length > 0) {
        for (const entry of selectedDatesWithIds) {
          for (const tag of this.selectedTags) {
            const tagsData = {
              key: tag?.Key || null,
              value: tag?.Value || null,
              date: entry.Date
            }
            tagsDataList.push(tagsData)
          }
        }
      }
      const tagKeys = tagsDataList.map(tag => tag.key)
      const uniqueTagKeys = Array.from(new Set(tagKeys))
      const editTagsData = {
        payload: {
          episodeId: this.$route.params.patientId,
          episodeTags: tagsDataList,
          deleteExistingTags: false,
          sticky: false,
          comment: this.modalNotesValue,
          actionTaken: `${this.getActionTakenForTagType(tagActionType)} ${uniqueTagKeys.join(', ')} tag(s) for `,
          logCategory: this.getLogCategoryForTagType(tagActionType),
          addTagsDateInActionTaken: true
        }
      }
      return editTagsData
    },
    getActionTakenForTagType (tagActionType) {
      if (tagActionType === 'ADD_TAGS') {
        return 'Added'
      } else if (tagActionType === 'REMOVE_TAGS') {
        return 'Removed'
      }
    },
    getLogCategoryForTagType (tagActionType) {
      if (tagActionType === 'ADD_TAGS') {
        return 'Tags_Added_For_Past_Doses'
      } else if (tagActionType === 'REMOVE_TAGS') {
        return 'Tags_Removed_For_Past_Doses'
      }
    },
    getEditDosePayload () {
      var selectedDates = this.codesSelected.map(m => {
        var parts = m.dayId.split('_')
        var date = new Date(parts[2], parts[1] - 1, parts[0])
        var userTimezoneOffset = date.getTimezoneOffset() * 60000
        return new Date(date.getTime() - userTimezoneOffset).toISOString()
      })
      const editDoseData = {
        patientId: this.$route.params.patientId,
        payload: {
          dates: selectedDates,
          addedNote: this.modalNotesValue,
          monitoringMethod: this.patientData?.episodeData?.stageData?.monitoringMethod || '',
          episodeId: this.$route.params.patientId
        }
      }
      return editDoseData
    },
    performDayClickAction (day, dayData, monthData) {
      if (this.codesSelected.length >= 60) {
        alert('Only 60 doses at a time selection allowed!')
        return
      }
      if (!this.tagsSelection) {
        if (this.codesSelected.length !== 0 && this.codesSelected.findIndex(a => a.code === dayData.code) === -1) return
        var allowedCodesForMarking = ['2', '6', '9']
        if (allowedCodesForMarking.indexOf(dayData.code) === -1) return
        if (this.doseMarkingByBtn && !this.highlightCodes.includes(dayData.code)) return
        if (!this.doseMarkingByBtn) this.unknwonDateSelection = true
        if (dayData.code === '6') {
          this.onHoverComponentList = {
            MARK_MANUAL_DOSE: 'mark_manual_dose',
            MARK_MISSED_DOSE: 'mark_missed_dose',
            ADD_TAGS: 'add_tags'
          }
          if (this.alterDoseUncertainAction === 'MISSED') {
            this.currentDoseMarkingState = 'MARK_MISSED_DOSE'
            this.confirmBtnText = this.alterDoseDropDownValues.MARK_MISSED_DOSE
          } else {
            this.currentDoseMarkingState = 'MARK_MANUAL_DOSE'
            this.confirmBtnText = this.alterDoseDropDownValues.MARK_MANUAL_DOSE
          }
        } else if (dayData.code === '2') {
          this.onHoverComponentList = {
            REMOVE_MISSED_DOSE: 'remove_missed_doses',
            ADD_TAGS: 'add_tags'
          }
          this.currentDoseMarkingState = 'REMOVE_MISSED_DOSE'
          this.confirmBtnText = this.alterDoseDropDownValues.REMOVE_MISSED_DOSE
        } else if (dayData.code === '9') {
          this.onHoverComponentList = {
            REMOVE_MANUAL_DOSE: 'remove_manual_doses',
            ADD_TAGS: 'add_tags'
          }
          this.currentDoseMarkingState = 'REMOVE_MANUAL_DOSE'
          this.confirmBtnText = this.alterDoseDropDownValues.REMOVE_MANUAL_DOSE
        }
        if (dayData.tagsForDay.length !== 0) {
          this.onHoverComponentList.REMOVE_TAGS = 'remove_tags' // add remove tags option in dropdown only if there is a tag added on that date.
        }
      }
      var dayId = day + '_' + monthData.monthNumber + '_' + monthData.year
      var inArray = this.codesSelected.findIndex(a => a.dayId === dayId)
      if (inArray !== -1) {
        this.codesSelected.splice(this.codesSelected.findIndex(a => a.dayId === dayId), 1)
      } else {
        this.codesSelected = [...this.codesSelected, { dayId: dayId, code: dayData.code }]
      }
      if (this.tagsSelection) {
        // disable confirm btn for tags if date selected has no tag
        if (dayData.tagsForDay.length === 0 && this.currentDoseMarkingState !== 'ADD_TAGS') {
          this.confirmBtnEnabledForTags = false
        }
        // remove tags for multiple dates is not allowed, therefore clearing add/removetags option selected
        if (this.codesSelected.length > 1 && this.currentDoseMarkingState !== 'ADD_TAGS') {
          this.confirmBtnEnabledForTags = false
          this.codesSelected.splice(0, this.codesSelected.length) // make codesSelected size 0 so that all dates gets unselected
          defaultToast(ToastType.Error, 'You cannot remove tags for multiple dates simultaneously.')
        }
      }
      // all doses unselected
      if (this.codesSelected.length === 0) {
        this.positiveActionCancelButton()
      } else {
        this.positiveAction = false
        if (!this.tagsSelection) { this.highlightCodes = [dayData.code] }
      }
    },
    performDayMouseoverCtrlAction (day, dayData, monthData) {
      this.performDayClickAction(day, dayData, monthData)
    },
    performActionLegendMouseenter (code) {
      if (!this.legendClicked && !this.unknwonDateSelection && !this.doseMarkingByBtn) {
        if (code[0] === '8') {
          this.datesToHighlight.push(this.firstDayDate)
          if (this.patientData != null && this.patientData.episodeData != null && this.patientData.episodeData.stageData.treatmentOutcome !== null) {
            this.datesToHighlight.push(this.endDateFormat)
          }
          return
        }
        this.highlightCodes = code
      }
    },
    performActionLegendMouseleave () {
      if (!this.legendClicked && !this.unknwonDateSelection && !this.doseMarkingByBtn) {
        this.highlightCodes = []
        this.datesToHighlight = []
      }
    },
    toPascalCase (str) {
      return str[0] + str.substr(1).toLowerCase()
    },
    legendBtnClickAction () {
      this.showLegend = !this.showLegend
      this.legendBtnText = this.showLegend ? 'hide_legend' : 'show_legend'
      this.toggleBtnIcon = this.showLegend ? 'caret-up' : 'caret-down'
    },
    performLegenedMouseClick (code) {
      if (!this.unknwonDateSelection && !this.doseMarkingByBtn) {
        this.legendClicked = !this.legendClicked
        this.highlightCodes = code
      }
    },
    positiveActionCancelButton () {
      this.doseMarkingByBtn = false
      this.positiveAction = true
      this.codesSelected = []
      this.unknwonDateSelection = false
      this.highlightCodes = []
      this.tagsSelection = false
      this.currentDoseMarkingState = ''
      this.disableModalConfirmBtn = false
      this.selectedTags = []
      this.confirmBtnEnabledForTags = true
    },
    selectOnHoverAction (index, value) {
      if (['ADD_TAGS', 'REMOVE_TAGS'].includes(value) && this.codesSelected.length === 1) {
        this.loadTags()
      }
      this.currentDoseMarkingState = value
      this.showModal = true
      this.confirmBtnText = value in this.alterDoseDropDownValues ? this.alterDoseDropDownValues[value] : this.alterTagsDropDownValues[value]
    },
    addRemoveTags (index, value) {
      this.positiveAction = false
      this.doseMarkingByBtn = true
      this.currentDoseMarkingState = value
      this.tagsSelection = true
      this.confirmBtnText = this.alterTagsDropDownValues[value]
    },
    formatTag (tag) {
      return { Key: this.$t(tag.tagName), Value: this.$t('tags_' + tag.tagName) + '(' + this.$t(tag.tagGroup) + ')' }
    },
    currentTagsWithTranslations (tags) {
      return tags.map(tag => this.formatTag(tag))
    }
  },
  computed: {
    getAdherenceData () {
      return this.$store.getters['Adherence/adherenceData']
    },
    maxDaysInGivenMonths () {
      var maxDays = 0
      // called before API call is made, and then again once API call succeeds
      if (Object.keys(this.getAdherenceData).length > 0) {
        for (var item of this.getAdherenceData.adherenceMonthWiseData) {
          maxDays = Math.max(maxDays, item.daysInMonth)
        }
      }
      return maxDays
    },
    tagsConfig () {
      return this.$store.getters['Adherence/tagsConfig']
    },
    tagsForSelector () {
      return this.tagsConfig.map(m => ({ Key: m.tagName, Value: this.$t('tags_' + m.tagName) + ' (' + this.$t(m.tagGroup) + ') ' }))
    },
    firstDayDate () {
      if (Object.keys(this.getAdherenceData).length > 0) {
        for (var item of this.getAdherenceData.adherenceMonthWiseData) {
          var dayIndex = 0
          for (var day of item.adherenceDataForCalendar) {
            if (day.code !== '7') {
              return dayIndex + '_' + item.monthNumber + '_' + item.year
            }
            dayIndex++
          }
        }
      }
      return ''
    },
    endDateFormat () {
      if (this.patientData != null && this.patientData.episodeData != null && this.patientData.episodeData.endDate != null) {
        var endDateParts = this.patientData.episodeData.endDate.split('T')[0].split('-')
        if (!isNaN(endDateParts[0]) && !isNaN(endDateParts[1]) && !isNaN(endDateParts[2].split(' ')[0])) {
          return (endDateParts[2].split(' ')[0] - 1) + '_' + (parseInt(endDateParts[1])) + '_' + endDateParts[0]
        }
      }
      return ''
    },
    ...mapState('Patient', ['patientData'])
  },
  mixins: [
    calendarDaySpeechBubbleMixin
  ]
}
</script>

<style lang='scss' scoped>
.legend-container {
  margin-top: 20px;
}
.show-legend-btn {
  float: left;
  position: relative;
  top: 50%;
  color: var(--primary-theme);
  margin-top: 10px;
}
.show-legend-btn button {
  text-decoration: underline;
}
.alter-tags {
  margin-right: 10px;
}

.modal-sub-text-label {
  font-size: 16px;
  color: #6E7191;
}

.dose-info-text {
    color: var(--text-primary-color);
    display: inline-block;
    margin-right: 20px;
    font-size: 12px;
}

.dose-info-text i {
    color: #2196F3;
    font-size: 18px;
    margin-right: 3px;
}
.greg_table tbody tr:nth-child(odd) {
  background-color: var(--table-header-gray)
}

.greg_table {
    font-size: 14px;
    margin: 0 auto;
}

.greg_table th {
    text-align: center;
    font-weight: 600;
    color: var(--table-header);
    border:none !important;
    background-color: var(--table-header-gray);
}

.greg_table td {
    text-align: center;
    min-width: 27px;
}

.cal_view table tr {
    border-bottom: 1px solid #D6D8E7;
}

.greg_day_container {
  width: 30px;
  height: 30px;
  padding: 1px;
}

.month_name, .month_year {
    font-weight: 500;
    font-size: 14px;
    color: var(--table-row-color);
}

.patient-month-header, .patient-year-header {
  padding: 10px 20px 10px 20px;
}

.day_selected {
  border: 3px solid var(--day-select-color);
}

.highlight {
    opacity: 0.2;
    transition: 0.3s;
}
.action_buttons {
    margin-bottom: 20px;
    text-align: right;
}
.action_buttons button {
    margin-top: 0.5%;
    margin-right: 10px;
}

.action_buttons button:last-child {
    margin-right: 0;
}

.info_box{
    display: flex;
    background-color: var(--info-box-background);
    border-radius: 6px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 13px;
    margin-top: 10px;
}

.info_box div {
    width: 33.3%;
    font-size: 16px;
    color: var(--text-primary-color);
    text-align: center;
}

.info_box div:first-child {
    text-align: left;
}

.info_box div:last-child {
    text-align: right;
}
.modal-sub-heading-text {
  margin-bottom: 10px;
}
.modal-additional-notes {
  margin-top: 10px;
}
</style>
