<template>
  <div class="page-container">
    <collapsable-sidebar :list="list" v-if="dataLoaded"></collapsable-sidebar>
    <router-view class="content-container"/>
  </div>
</template>

<script>
import CollapsableSidebar from '../../shared/components/CollapsableSidebar.vue'
import { mapState, mapActions } from 'vuex'
export default {
  name: 'Dashboard',
  components: {
    CollapsableSidebar
  },
  data () {
    return {
      list: this.sidebarDetails,
      dataLoaded: false
    }
  },
  computed: {
    ...mapState('Sidebar', ['sidebarDetails'])
  },
  async mounted () {
    await this.getSidebar()
    this.list = this.sidebarDetails
    this.dataLoaded = true
  },
  methods: {
    ...mapActions('Sidebar', ['getSidebarPermissions']),
    async getSidebar () {
      await this.getSidebarPermissions()
    }
  }
}
</script>

<style lang='scss' scoped>
.page-container{
  position: fixed;
  // margin-top: 3rem;
  height: calc(100vh - 3rem);
  width: 100%;
  display: flex;
  background: var(--background-bg-color);
}
.content-container {
  padding: 20px;
  width: 100%;
  overflow-y: scroll;
}
</style>
