import { mapActions } from 'vuex'

const commonChartOptions = {
  tooltip: {
    style: {
      fontSize: '14px',
      fontFamily: "IBM Plex Sans', sans-serif"
    },
    fillSeriesColor: false
  },
  legend: {
    position: 'bottom',
    horizontalAlign: 'left',
    offsetY: 5,
    itemMargin: {
      vertical: 5,
      horizontal: 0
    },
    fontSize: '12px',
    fontFamily: "IBM Plex Sans', sans-serif",
    fontWeight: 'normal',
    labels: {
      colors: '#011738'
    }
  },
  dataLabels: {
    style: {
      colors: ['#011738']
    },
    dropShadow: {
      enabled: false
    }
  },
  plotOptions: {
    pie: {
      customScale: 0.85,
      offsetY: 38,
      dataLabels: {
        offset: 40
      }
    }
  },
  chart: {
    height: '400px',
    zoom: {
      autoScaleYaxis: true
    },
    width: '400px'
  }
}

const areaOptions = {
  chart: {
    type: 'area',
    zoom: {
      autoScaleYaxis: true,
      type: 'y'
    }
  },
  yaxis: {
    title: {
      text: 'Patients'
    }
  },
  xaxis: {
    type: 'datetime',
    min: new Date('01 Mar 2012').getTime(),
    tickAmount: 6
  },
  tooltip: {
    x: {
      show: false,
      format: 'dd MMM yyyy'
    },
    y: {
      title: {
        formatter: () => 'Patients'
      }
    },
    style: {
      fontSize: '14px',
      fontFamily: "IBM Plex Sans', sans-serif"
    }
  },
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.5,
      opacityTo: 0.9,
      stops: [100]
    }
  },
  dataLabels: {
    enabled: false
  }
}

export const ChartMixin = {
  data () {
    return {
      chartOptions: {},
      chartData: [],
      loading: true,
      dataOptions: {},
      dataHeadings: {}
    }
  },
  props: {
    chartType: String,
    url: String
  },
  async mounted () {
    var data = await this.loadChart(this.url)
    this.chartOptions = {
      ...commonChartOptions
    }
    this.chartOptions.labels = data.labels
    this.chartOptions.colors = data.colors
    this.chartData = data.series
    this.dataHeadings = data.summaryHeadings
    if (this.chartType !== 'area') {
      this.chartOptions.labels = this.chartOptions.labels.map(label => this.$t(label))
    }
    if (this.chartType === 'area') {
      for (var i = 0; i < this.chartData.length; i++) {
        this.chartData[i][0] = new Date(this.chartData[i][0]).getTime()
      }
      this.chartOptions = {
        ...commonChartOptions,
        ...areaOptions
      }
      if (data.dateOptions != null) {
        areaOptions.xaxis.min = new Date(data.dateOptions.lastMonth.fromTimestamp).getTime()
        this.selection = data.selection
        this.chartData = [{
          data: data.series
        }]
        this.$root.$emit('recievedDateOptions', data.dateOptions)
        this.dataOptions = data.dateOptions
      }
    }
    this.loading = false
  },
  methods: {
    ...mapActions('Overview', ['loadChart'])
  }
}
