<template>
  <div>
        <div class="card">
          <div class="title">{{$t(title)}}</div>
          <div class="description">{{$t(description)}}</div>
        </div>
        <div v-if='!isFormActive' class="sub-card" v-on:click="loadReportForm(title, name, formName)">
          <div class="sub-card-text">{{$t(`go_to_report`)}}</div>
          <div class="sub-card-arrow">
            <icon-base width="14"
            height="14" iconName="angle-down"></icon-base>
          </div>
        </div>
  </div>
</template>

<script>

import IconBase from '../../../../shared/components/IconBase'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'ReportSelection',
  methods: {
    ...mapActions('Reports', ['setActiveReport']),
    loadReportForm (title, name, formName) {
      /* istanbul ignore next */
      const data = {
        Key: name,
        Value: title,
        Form: formName
      }
      /* istanbul ignore next */
      this.setActiveReport(data)
    }
  },
  computed: {
    ...mapState('Reports', [
      'isFormActive',
      'activeReportTitle'
    ])
  },
  components: {
    IconBase
  },
  props: {
    name: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    formName: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
.card{
  padding:20px;
  margin:10px;
  margin-bottom: 0px;
  height:175px;
  width:380px;
  font-family: 'IBM Plex Sans', sans-serif !important;
  border: $reportsCardBorder;
  background: var(--background-color-primary-light-dark);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.sub-card{
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin:10px;
  margin-top: 0px;
  padding-left: 20px;
  border-top: 1px solid $line;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  width:  380px;
  height: 60px;
  background: var(--background-color-primary-light-dark);
}
.sub-card-text {
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: var(--primary-color-text);
}
.title{
  color: var(--day-select-color);
  font-style: normal;
  font-weight: 600;
  padding: 5px;
}
.description{
  color: var(--day-select-color);
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  padding: 5px;
}
.sub-card-arrow{
  position: relative;
  right: 10px;
  transform: rotate(-90deg);
}
</style>
