<template>
  <div>
    <Breadcrumbs :items="breadcrumbs"></Breadcrumbs>
    <div class="header">
        {{$t(`Generate_QR`)}}
    </div>
    <v-card variant="outlined" class="booklet-options">
      <div class="input-container">
        <Input  :label="$t('Enter Number of pages')" :placeholderText="'Ex: 10'" v-model="numberOfPages"/>
        <div class="subtext"> 1 Page = 121 QR stickers</div>
      </div>
      <Button class="button-align pull-right" :label="$t(`download`)" @click="handleGenerateQRBooklet"/>
    </v-card>

  </div>
</template>

<script>
import Breadcrumbs from '../../../shared/components/Breadcrumbs.vue'
import Button from '@/app/shared/components/Button.vue'
import Input from '@/app/shared/components/Input.vue'
import { defaultToast, ToastType } from '../../../../utils/toastUtils'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Diagnostics',
  data () {
    return {
      numberOfPages: 0,
      breadcrumbs: [
        {
          label: 'Dashboard',
          link: '/dashboard/overview',
          class: ''
        },
        {
          label: 'Generate QR Code Booklet',
          link: '',
          class: 'active'
        }
      ]
    }
  },
  components: {
    Breadcrumbs,
    Button,
    Input
  },
  methods: {
    ...mapActions('Diagnostics', [
      'generateQrCodeBooklet'
    ]),
    async handleGenerateQRBooklet (data) {
      if (this.numberOfPages <= 0 || this.numberOfPages >= 11) {
        defaultToast(ToastType.Warning, 'Please select between 1-10 pages')
      } else {
        await this.generateQrCodeBooklet(this.numberOfPages)
        if (this.qrDownloadLink) {
          window.open(this.qrDownloadLink, '_blank')
          this.numberOfPages = 0
        }
      }
    }
  },
  computed: {
    ...mapState('Diagnostics', [
      'qrDownloadLink'
    ])
  }
}
</script>

<style scoped>
.header{
  font-family: 'IBM Plex Sans',serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 44px;
  padding-bottom: 8px;
  align-items: center;
  letter-spacing: 0.005em;
  color: var(--day-select-color);
  padding-left: 10px;
}

.booklet-options {
  padding: 16px 16px 24px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pull-right {
  margin-left: auto;
  float: right;
  justify-content: flex-end;
  margin-top: 22px;
  margin-bottom: 42px;
}
.subtext {
  font-size: 14px;
  margin-left: 224px;
}

.input-container {
  padding-top: 16px;
}
</style>
