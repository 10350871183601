import { ApiServerClient, errorCallback } from '../Api'
import { Map, List } from 'immutable'
import { isNotNullNorEmpty, createMapOf, createMapListOf, isNullOrUndefined } from '../../utils/Objects'
import { getCookie } from '../../../../utils/cookieUtils'
import EventBus from '@/app/shared/event-bus.js'
import { RegistryServerClient } from '../RegistryApi'

export default {
  namespaced: true,
  state: {
    isSaving: false,
    isLoading: false,
    isEditing: true,
    hasFormError: false,
    adherenceTechnology: { MERM: false, VOT: false },
    existingData: null,
    existingVModel: {},
    form: {},
    title: '',
    remoteUpdateConfigsMappedByField: Map(),
    remoteUpdateConfigs: [],
    hierarchyConfigs: Map(),
    valueDependencies: List(),
    formPartsMappedByName: null,
    fieldsMappedByFormPartName: Map(),
    fieldsMappedByNameOriginal: Map(),
    fieldsMappedByName: null,
    allFields: null,
    visibilityDependencies: List(),
    isRequiredDependencies: List(),
    filterDependencies: List(),
    visibilityDependenciesMappedByFormPartName: null,
    dateConstraintDependency: List(),
    formFieldsByFormPartName: {},
    saveEndpoint: '',
    saveText: '',
    saveUrlParams: '',
    getFormUrlParams: '',
    replaceExistingDataFromServer: true,
    recaptchaVerified: false,
    otp: '',
    editAccess: false,
    valuePropertyDependencies: List(),
    compoundValueDependencies: List(),
    requestMethod: ''
  },
  mutations: {
    START_LOADING (state) {
      state.isLoading = true
    },
    END_LOADING (state) {
      state.isLoading = false
    },
    START_SAVING (state) {
      state.isSaving = true
    },
    END_SAVING (state) {
      state.isSaving = false
    },
    SET_EDITING (state, data) {
      state.isEditing = data
    },
    SET_EXISTING_DATA (state, data) {
      state.existingData = data
    },
    UPDATE_ADHERENCE_TECHNOLOGY (state, data) {
      state.adherenceTechnology.MERM = data.hasMerm
      state.adherenceTechnology.VOT = data.hasVot
    },
    SET_EXISTING_VMODEL (state, data) {
      if (isNullOrUndefined(data)) data = {}
      const tempVModel = {}
      var addedFields = []
      const tableFormPartNameLengthMap = {}
      state.formPartsMappedByName
        .keySeq()
        .toArray()
        .filter(
          fp => state.allFields.filter(f => f.PartName === fp).length > 0
        )
        .forEach(fp => {
          const fPartModel = {}
          var formPart = state.formPartsMappedByName.get(fp)
          if (formPart.Type === 'table-form-part' || formPart.Type === 'checkbox-grid-form-part') {
            var tableRows = []
            if (data[formPart.RowDataName]) {
              data[formPart.RowDataName].forEach(row => {
                var newRow = {}
                state.allFields
                  .filter(f => f.PartName === fp)
                  .forEach(field => {
                    newRow[field.Name] = row[field.Name]
                    if (field.Component === 'app-datepicker' && newRow[field.Name] && typeof newRow[field.Name] === 'string') {
                      var today = new Date()
                      var date = new Date(newRow[field.Name].replace(/(\d{2})-(\d{2})-(\d{4})/, '$2/$1/$3'))
                      date.setHours(today.getHours())
                      date.setMinutes(today.getMinutes())
                      newRow[field.Name] = date
                    }
                    var origField = state.fieldsMappedByName.get(field.Key)
                    if ((field.Component === 'app-search-select' || field.Component === 'app-select') &&
                      row[field.Name] &&
                      !isNullOrUndefined(origField.OptionsWithKeyValue)) {
                      if (typeof row[field.Name] === 'object') {
                        newRow[field.Name] = origField.OptionsWithKeyValue
                          .filter(o => o.Value === newRow[field.Name] ||
                            o.Value.toLowerCase() === newRow[field.Name].Value.toLowerCase() ||
                            o.Value === newRow[field.Name].Key ||
                            o.Key === newRow[field.Name] ||
                            o.Key === newRow[field.Name].Key)[0]
                      }
                      if (typeof row[field.Name] === 'object' &&
                        isNotNullNorEmpty(row[field.Name].Value) &&
                        !newRow[field.Name]) {
                        newRow[field.Name] = row[field.Name].Value
                      }
                    }
                    if (isNotNullNorEmpty(field.HierarchySelectionConfigs)) {
                      state.hierarchyConfigs = state.hierarchyConfigs.set(
                        field.Name,
                        field.HierarchySelectionConfigs
                      )
                    }

                    var newf = JSON.parse(JSON.stringify(origField))
                    newf.Key = newf.Key.substring(0, newf.Key.length - 1) + tableRows.length

                    addedFields.push(newf)
                  })
                tableRows.push(newRow)
              })
            } else if (formPart.Type === 'table-form-part' && state.isEditing) {
              var newRow = {}
              state.allFields
                .filter(f => f.PartName === fp)
                .forEach(field => {
                  newRow[field.Name] = field.Value
                  if (isNotNullNorEmpty(field.HierarchySelectionConfigs)) {
                    state.hierarchyConfigs = state.hierarchyConfigs.set(
                      field.Name,
                      field.HierarchySelectionConfigs
                    )
                  }
                  var origField = state.fieldsMappedByName.get(field.Key)
                  var newf = JSON.parse(JSON.stringify(origField))
                  newf.Key = newf.Key.substring(0, newf.Key.length - 1) + tableRows.length
                  addedFields.push(newf)
                })
              tableRows.push(newRow)
            }

            fPartModel[formPart.RowDataName] = tableRows
          } else {
            state.fieldsMappedByFormPartName
              .get(fp)
              .valueSeq()
              .toArray()
              .forEach(field => {
                function setFieldDataToHypenIfNull (field, data, isEditing, formName) {
                  const fieldTempData = data[field.Name]
                  if (!isEditing && (isNullOrUndefined(fieldTempData) || fieldTempData === '') && formName === 'BasicDetails') {
                    if (field.Component === 'app-input-field' || field.Component === 'app-input-field-group') {
                      return '-'
                    } else if (field.Component === 'app-select') {
                      return { Key: '-', Value: '-' }
                    } else {
                      return fieldTempData
                    }
                  }
                  return fieldTempData
                }
                var fieldData = setFieldDataToHypenIfNull(field, data, state.isEditing, state.form.name)
                if (isNullOrUndefined(fieldData) && !isNullOrUndefined(data[formPart.RowDataName])) {
                  fieldData = data[formPart.RowDataName][field.Name]
                }
                if (!isNotNullNorEmpty(fieldData) && isNotNullNorEmpty(state.existingVModel[fp]) && isNotNullNorEmpty(state.existingVModel[fp][field.Name])) {
                  fieldData = state.existingVModel[fp][field.Name]
                }
                if (field.Component === 'app-datepicker' && fieldData && typeof fieldData === 'string') {
                  var today = new Date()
                  var date = new Date(fieldData.replace(/(\d{2})-(\d{2})-(\d{4})/, '$2/$1/$3'))
                  date.setHours(today.getHours())
                  date.setMinutes(today.getMinutes())
                  fieldData = date
                }
                var origField = state.fieldsMappedByName.get(field.Key)

                if (field.Component === 'app-search-select' && fieldData && !isNullOrUndefined(origField.OptionsWithKeyValue)) {
                  fieldData = origField.OptionsWithKeyValue.filter(o => o.Key + '' === fieldData + '' || o.Key + '' === fieldData.Key + '')[0]
                } else if (field.Component === 'app-search-select' && fieldData && !isNullOrUndefined(origField.AllOptionsWithLabel)) {
                  fieldData = origField.AllOptionsWithLabel.filter(o => o.Key + '' === fieldData || o.Key + '' === fieldData.Key + '')[0]
                } else if (field.Component === 'app-select' && fieldData && !isNullOrUndefined(origField.OptionsWithKeyValue)) {
                  fieldData = origField.OptionsWithKeyValue.filter(o => o.Key + '' === fieldData + '' || o.Key + '' === fieldData.Key + '')[0]
                } else if (field.Component === 'app-select' && fieldData && !isNullOrUndefined(origField.AllOptionsWithLabel)) {
                  fieldData = origField.AllOptionsWithLabel.filter(o => o.Key + '' === fieldData + '' || o.Key + '' === fieldData.Key + '')[0]
                } else if (field.Component === 'app-multiselect' && fieldData && !isNullOrUndefined(origField.OptionsWithKeyValue)) {
                  fieldData = origField.OptionsWithKeyValue.filter(o => o.Key + '' === fieldData + '' || o.Key + '' === fieldData.Key + '')[0]
                } else if (field.Component === 'app-multiselect' && fieldData && !isNullOrUndefined(origField.AllOptionsWithLabel)) {
                  fieldData = origField.AllOptionsWithLabel.filter(o => o.Key + '' === fieldData + '' || o.Key + '' === fieldData.Key + '')[0]
                }
                fPartModel[field.Name] = fieldData
                if (isNotNullNorEmpty(field.HierarchySelectionConfigs)) {
                  state.hierarchyConfigs = state.hierarchyConfigs.set(
                    field.Name,
                    field.HierarchySelectionConfigs
                  )
                }
              })
          }
          tempVModel[fp] = fPartModel
          if (state.isEditing) {
            if (formPart.Type === 'table-form-part' && !isNullOrUndefined(data[formPart.Name]) && data[formPart.Name].length) {
              tableFormPartNameLengthMap[formPart.Name] = data[formPart.Name].length
            }
          }
        })
      state.existingVModel = tempVModel
      var updatedFields = Array.from(state.fieldsMappedByName.values())
        .filter(a => state.formPartsMappedByName.get(a.PartName).Type !== 'table-form-part' && state.formPartsMappedByName.get(a.PartName).Type !== 'checkbox-grid-form-part'
        )
      updatedFields = [...updatedFields, ...addedFields]
      state.fieldsMappedByName = createMapOf('Key', updatedFields)
      state.fieldsMappedByFormPartName = createMapListOf('PartName', updatedFields)
      if (state.isEditing) {
        setTimeout(() => {
          if (Object.keys(tableFormPartNameLengthMap).length > 0) {
            Object.entries(tableFormPartNameLengthMap).forEach(([name, length]) => {
              for (let i = 0; i < length; i++) {
                state.remoteUpdateConfigs.forEach(f => {
                  EventBus.$emit('CENTRAL_REMOTE_DATA_UPDATE', f, i)
                })
              }
            })
          }
          state.remoteUpdateConfigs.forEach(f =>
            EventBus.$emit('CENTRAL_REMOTE_DATA_UPDATE', f)
          )
        }, 300)
      }
    },
    SET_FORM (state, data) {
      state.form.name = data.Name
      state.form.title = data.Title
      state.title = data.Title
    },
    SET_FORM_PARTS (state, data) {
      state.formPartsMappedByName = createMapOf('Name', data)
    },
    SET_REMOTE_UPDATE_CONFIGS (state, data) {
      let { remoteUpdateConfigsMappedByField } = state
      state.remoteUpdateConfigs = data

      // TODO: Create an event map and send triggers separately
      for (const conf of data) {
        for (const cfield of conf.ValueDependency) {
          // check existing
          let f = remoteUpdateConfigsMappedByField.get(cfield.Field)
          if (isNullOrUndefined(f)) {
            f = List()
          }
          f = f.push({ ...conf })
          remoteUpdateConfigsMappedByField = remoteUpdateConfigsMappedByField.set(
            cfield.Field,
            f
          )
        }
      }
      state.remoteUpdateConfigsMappedByField = remoteUpdateConfigsMappedByField
    },
    SET_FIELDS (state, { formParts, data }) {
      state.allFields = data
      formParts.forEach(fp => {
        if (fp.Type === 'table-form-part' || fp.Type === 'checkbox-grid-form-part') {
          var partFields = data.filter(f => f.PartName === fp.Name)
          partFields.forEach(pf => {
            pf.Key = pf.Key + '0'
          })
        }
      })
      state.fieldsMappedByName = createMapOf('Key', data)
      state.fieldsMappedByNameOriginal = createMapOf('Name', data)
      state.fieldsMappedByFormPartName = createMapListOf('PartName', data)
    },
    SET_VISIBILITY_DEPENDENCIES (state, data) {
      state.visibilityDependencies = List(data)
      state.visibilityDependenciesMappedByFormPartName = createMapListOf(
        'PartName',
        data
      )
    },
    SET_DATE_CONSTRAINT_DEPENDENCIES (state, data) {
      state.dateConstraintDependency = List(data)
    },
    SET_VALUE_DEPENDENCIES (state, data) {
      state.valueDependencies = List(data)
    },
    SET_IS_REQUIRED_DEPENDENCIES (state, data) {
      state.isRequiredDependencies = List(data)
    },
    SET_FILTER_DEPENDENCIES (state, data) {
      state.filterDependencies = List(data)
    },
    SET_VALUE_PROPERTY_DEPENDENCIES (state, data) {
      state.valuePropertyDependencies = List(data)
    },
    SET_COMPOUND_VALUE_DEPENDENCIES (state, data) {
      state.compoundValueDependencies = List(data)
    },
    UPDATE_HAS_FORM_ERROR (state) {
      let { hasFormError } = state
      const { fieldsMappedByName, formPartsMappedByName, fieldsMappedByFormPartName, existingVModel } = state
      hasFormError = !(fieldsMappedByName === null || undefined === fieldsMappedByName) &&
        fieldsMappedByName
          .valueSeq()
          .filter(v => {
            let validError = v.hasError && v.IsVisible && formPartsMappedByName.get(v.PartName).IsVisible
            // Is form error applicable for checkbox-grid-form-part only if the checkbox component field is true
            if (validError && formPartsMappedByName.get(v.PartName).Type === 'checkbox-grid-form-part' && v.Component !== 'app-checkbox') {
              var firstNumberRegEx = /\d+$/
              var firstNumberIndex = v.Key.search(firstNumberRegEx)
              var fieldKey = v.Key.substring(firstNumberIndex, v.Key.length)
              const localFields = fieldsMappedByFormPartName.get(v.PartName).filter(f => f.Key.includes(fieldKey))
              const appCheckBoxComponent = localFields.filter(f => f.Component === 'app-checkbox').toArray()[0]
              let checkBoxComponentValue = existingVModel[v.PartName][v.PartName][fieldKey][appCheckBoxComponent.Name]
              if (typeof (checkBoxComponentValue) === 'string') {
                checkBoxComponentValue = checkBoxComponentValue === 'true'
              }
              validError = validError && checkBoxComponentValue
            }
            return validError
          })
          .count() > 0
      state.hasFormError = hasFormError
    },
    UPDATE_FIELD (state, { key, attribute, data }) {
      const { fieldsMappedByName } = state
      let updatedMap = fieldsMappedByName
      if (fieldsMappedByName && fieldsMappedByName.has(key)) {
        const existing = fieldsMappedByName.get(key)
        updatedMap = updatedMap.set(key, {
          ...existing,
          [attribute]: data
        })
        state.fieldsMappedByName = updatedMap
        state.fieldsMappedByFormPartName = createMapListOf(
          'PartName',
          updatedMap.valueSeq().toArray()
        )
      }
    },
    UPDATE_FORM_PART (state, { name, attribute, data }) {
      const { formPartsMappedByName } = state
      let updatedMap = formPartsMappedByName
      const existing = formPartsMappedByName.get(name)

      updatedMap = updatedMap.set(name, {
        ...existing,
        [attribute]: data
      })
      state.formPartsMappedByName = updatedMap
    },
    ADD_REMOVE_REQUIRED_VALIDATION (state, { field, addRequired, self }) {
      const { fieldsMappedByName } = state
      let updatedMap = fieldsMappedByName
      const existing = fieldsMappedByName.get(field)
      existing.IsRequired = addRequired
      if (!isNullOrUndefined(existing)) {
        let existingValidations = isNullOrUndefined(existing.Validations)
          ? Map()
          : createMapOf('Type', existing.Validations.And)

        if (addRequired) {
          existingValidations = existingValidations
            .set('Required', {
              // ErrorMessage: self.$t('error_field_required'),
              ErrorMessage: 'error_field_required',
              RequiredOnlyWhen: null,
              Type: 'Required',
              ValidationParams: null,
              ValidationUrl: null
            })
            .valueSeq()
            .sortBy(v => v.Type)
            .reverse()
            .toArray()
        } else {
          existingValidations = existingValidations
            .remove('Required')
            .valueSeq()
            .sortBy(v => v.Type)
            .reverse()
            .toArray()
        }
        updatedMap = updatedMap.set(field, {
          ...existing,
          Validations: { And: existingValidations }
        })
        state.fieldsMappedByName = updatedMap
        state.fieldsMappedByFormPartName = createMapListOf(
          'PartName',
          updatedMap.valueSeq().toArray()
        )
      }
    },
    ADD_FORM_FIELDS_CONFIG (state, part, data) {
      state.formFieldsByFormPartName[part] = data
    },
    SET_SAVE_ENDPOINT (state, data) {
      state.saveEndpoint = data
    },
    SET_SAVE_TEXT (state, data) {
      state.saveText = data
    },
    SET_SAVE_URL_PARAMS (state, data) {
      state.saveUrlParams = data
    },
    SET_GET_FORM_URL_PARAMS (state, data) {
      state.getFormUrlParams = data
    },
    SET_REPLACE_EXISTING_DATA_FROM_SERVER (state, data) {
      state.replaceExistingDataFromServer = data
    },
    SET_REQUEST_METHOD (state, data) {
      state.requestMethod = data
    },
    ADD_FIELDS (state, data) {
      var updatedFields = Array.from(state.fieldsMappedByName.values())
      updatedFields = [...updatedFields, ...data]
      state.fieldsMappedByName = createMapOf('Key', updatedFields)
      state.fieldsMappedByFormPartName = createMapListOf('PartName', updatedFields)
    },
    REMOVE_FIELDS (state, data) {
      var { fields, formPart, index } = data
      var removeKeys = fields.map(d => d.Key)
      var updatedFields = Array.from(state.fieldsMappedByName.values())
      updatedFields = updatedFields.filter(uf => !removeKeys.includes(uf.Key))

      if (state.formPartsMappedByName.get(formPart).Type === 'table-form-part' || state.formPartsMappedByName.get(formPart).Type === 'check-box-form-part') {
        updatedFields.forEach(uf => {
          if (uf.PartName === formPart && 'Key' in uf && uf.Key.substring(uf.Key.length - 1) > index) {
            var rowIndex = parseInt(uf.Key.substring(uf.Key.length - 1, uf.Key.length))
            uf.Key = uf.Key.substring(0, uf.Key.length - 1) + (rowIndex - 1)
          }
        })
      }

      state.fieldsMappedByName = createMapOf('Key', updatedFields)
      state.fieldsMappedByFormPartName = createMapListOf('PartName', updatedFields)
    },
    CLEAR_FORM_CONFIG (state) {
      state.isSaving = false
      state.hasFormError = false
      state.adherenceTechnology = { MERM: false, VOT: false }
      state.existingData = null
      state.existingVModel = {}
      state.form = {}
      state.title = ''
      state.hierarchyConfigs = Map()
      state.valueDependencies = List()
      state.formPartsMappedByName = null
      state.fieldsMappedByFormPartName = Map()
      state.fieldsMappedByName = null
      state.allFields = null
      state.visibilityDependencies = List()
      state.isRequiredDependencies = List()
      state.filterDependencies = List()
      state.visibilityDependenciesMappedByFormPartName = null
      state.dateConstraintDependency = List()
      state.valuePropertyDependencies = List()
      state.compoundValueDependencies = List()
      state.formFieldsByFormPartName = {}
      state.saveEndpoint = ''
      state.saveText = ''
      state.requestMethod = ''
    },
    UPDATE_EXISTING_VMODEL (state, data) {
      state.existingVModel = Object.assign({}, state.existingVModel, { [data.formPart]: { ...state.existingVModel[data.formPart], [data.field]: data.value } })
    }
  },
  actions: {
    async getFormData ({ commit, state }, url) {
      return RegistryServerClient.get(url, null, errorCallback)
    },
    async loadForm ({ commit, state, dispatch }, form) {
      let localSelectedLang = getCookie('languageCode')
      if (!localSelectedLang) {
        localSelectedLang = 'en'
      }
      let url = `/v1/form?formName=${form.name}&loadFormData=${form.loadFormData}&language=${localSelectedLang}`
      if (form.getFormUrlParams !== '') {
        url = url + `&${form.getFormUrlParams}`
      }
      commit('START_LOADING')
      if (state.replaceExistingDataFromServer) {
        commit('CLEAR_FORM_CONFIG')
      }
      // TODO:include form name where necessary
      try {
        const formData = await dispatch('getFormData', url)
        if (formData !== undefined && !formData.Success) {
          throw new Error(formData.Error.Message)
        }
        if (formData !== undefined) {
          if (formData.Data.ExistingData && formData.Data.ExistingData.EpisodeId === 0) {
            // throw new Error(form.self.$t('invalid_patient_or_dispensation_id'))
            throw new Error('invalid_patient_or_dispensation_id')
          }
          commit('SET_FIELDS', { formParts: formData.Data.Form.Parts, data: formData.Data.Form.Fields })
          commit('SET_FORM_PARTS', formData.Data.Form.Parts)
          commit('SET_FORM', formData.Data.Form)
          commit(
            'SET_VISIBILITY_DEPENDENCIES',
            formData.Data.Form.VisibilityDependencies
          )
          commit('SET_VALUE_DEPENDENCIES', formData.Data.Form.ValueDependencies)

          if (formData.Data.Form.RemoteUpdateConfigs) {
            commit(
              'SET_REMOTE_UPDATE_CONFIGS',
              formData.Data.Form.RemoteUpdateConfigs
            )
          }

          commit('SET_VALUE_PROPERTY_DEPENDENCIES', formData.Data.Form.ValuePropertyDependencies)
          commit('SET_COMPOUND_VALUE_DEPENDENCIES', formData.Data.Form.CompoundValueDependencies)
          commit(
            'SET_DATE_CONSTRAINT_DEPENDENCIES',
            formData.Data.Form.DateConstraintDependencies
          )
          commit(
            'SET_IS_REQUIRED_DEPENDENCIES',
            formData.Data.Form.IsRequiredDependencies
          )
          commit(
            'SET_FILTER_DEPENDENCIES',
            formData.Data.Form.FilterDependencies
          )
          if (state.replaceExistingDataFromServer) {
            commit('SET_EXISTING_VMODEL', formData.Data.ExistingData)
            commit('SET_EXISTING_DATA', formData.Data.ExistingData)
          } else {
            commit('SET_EXISTING_VMODEL', state.existingData)
          }
          commit('SET_SAVE_ENDPOINT', formData.Data.Form.SaveEndpoint)
          commit('SET_SAVE_TEXT', formData.Data.Form.SaveTextKey)
          commit('SET_REPLACE_EXISTING_DATA_FROM_SERVER', true)
          commit('SET_REQUEST_METHOD', formData.Data.Form.requestMethod)
        }
      } catch (ex) {
        errorCallback(ex)
      }
      commit('END_LOADING')
    },
    async save ({ commit, state }, data) {
      var params = ''
      if (!isNullOrUndefined(data.saveFormUrlParams)) {
        params = data.saveFormUrlParams
      }
      const requestMethod = state.requestMethod
      let response = null
      switch (requestMethod) {
        case 'POST':
          response = await RegistryServerClient.post(state.saveEndpoint + params, data.payload, errorCallback)
          break
        case 'PUT':
          response = await RegistryServerClient.put(state.saveEndpoint + params, data.payload, errorCallback)
          break
        case 'PATCH':
          response = await RegistryServerClient.patch(state.saveEndpoint + params, data.payload, errorCallback)
          break
        case 'DELETE':
          response = await RegistryServerClient.delete(state.saveEndpoint + params, data.payload, errorCallback)
          break
        default:
          response = await RegistryServerClient.post(state.saveEndpoint + params, data.payload, errorCallback)
          break
      }
      return response
    },
    async getRemoteData (context, param) {
      const { url, params } = param
      return RegistryServerClient.get(url, params, null)
    },
    async commonRemoteCall (url, param) {
      return await ApiServerClient.get(url + param)
    },
    updateExistingData ({ commit }, params) {
      commit('SET_EXISTING_VMODEL', params)
      commit('SET_EXISTING_DATA', params)
    },
    updateField ({ commit }, params) {
      commit('UPDATE_FIELD', params)
      // recalculate errors everytime field Visibility changes
      commit('UPDATE_HAS_FORM_ERROR')
    },

    checkValidInput ({ commit }) {
      commit('UPDATE_HAS_FORM_ERROR')
    },

    setFieldError ({ commit }, params) {
      const { key, data } = params
      commit('UPDATE_FIELD', { key, attribute: 'hasError', data: true })
      commit('UPDATE_FIELD', { key, attribute: 'errorMessage', data })
      commit('UPDATE_HAS_FORM_ERROR')
    },
    unsetFieldError ({ commit }, params) {
      const { key } = params
      commit('UPDATE_FIELD', { key, attribute: 'hasError', data: false })
      commit('UPDATE_FIELD', { key, attribute: 'errorMessage', data: null })
      commit('UPDATE_HAS_FORM_ERROR')
    },
    updateFormPart ({ commit }, params) {
      commit('UPDATE_FORM_PART', params)
    },

    startSaving ({ commit }) {
      commit('START_SAVING')
    },

    endSaving ({ commit }) {
      commit('END_SAVING')
    },
    addRemoveRequiredValidation ({ commit }, data) {
      commit('ADD_REMOVE_REQUIRED_VALIDATION', data)
    },
    setIsEditing ({ commit }, isEditing) {
      commit('SET_EDITING', isEditing)
    },
    setExistingData ({ commit, state }, data) {
      var rowData = state.existingData[data.rowsName][data.index]
      commit('SET_EXISTING_DATA', rowData)
      commit('SET_REPLACE_EXISTING_DATA_FROM_SERVER', false)
    },
    addFields ({ commit }, data) {
      commit('ADD_FIELDS', data)
    },
    removeFields ({ commit }, data) {
      commit('REMOVE_FIELDS', data)
    },
    updateExistingVModel ({ commit }, data) {
      commit('UPDATE_EXISTING_VMODEL', data)
    },
    setGetFormUrlParams ({ commit }, params) {
      commit('SET_GET_FORM_URL_PARAMS', params)
    }
  }
}
